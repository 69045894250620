import Vue from 'vue';
import moment from 'moment';

Vue.filter('mapDate', value => {
  if(value) {
    const date = new Date(value);
    return moment(date).format('DD-MM-YYYY');
  }
  return '-'
})
