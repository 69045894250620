import Vue from 'vue'
import auth from './auth'
import users from './users'
import posts from './posts'
import stats from '@/http/stats'

Vue.prototype.$httpService = {
  auth,
  users,
  stats,
  posts,
};
