import { stringifyGetParams } from '@/helpers/api-params'
import axios from '@/libs/axios'

export default {
  getPosts(sponsored= false, page = 1, pageSize = 10, search = null, isReported = null,  filters) {
    const getParams = stringifyGetParams({page, pageSize, createdBy: search, isReported: isReported, ...filters})
    const url = sponsored ? '/admin/post-feeds/sponsored?' : '/admin/post-feeds?'
    return axios.get(url + getParams)
  },
  changePostBlockStatus(uid) {
    return axios.patch(`/admin/post-feeds/${uid}/block`)
  },
  changePostFavouriteStatus(uid) {
    return axios.patch(`/admin/post-feeds/${uid}/favourite`)
  },
  changePostFavouritePlusStatus(uid) {
    return axios.patch(`/admin/post-feeds/${uid}/favourite-plus`)
  },
  deletePost(uid) {
    return axios.delete('/feed-posts/' + uid)
  },
  getSponsoredPositions() {
    return axios.get(`/admin/post-feeds/sponsored-positions`)
  },
  addToSponsored(uid, position, fromDate, toDate, method = 'post') {
    return axios[method](`/feed-posts/${uid}/sponsor`, {
      position,
      fromDate,
      toDate
    })
  },
  deleteSponsoredPost(uid) {
    return axios.delete(`/feed-posts/${uid}/sponsor`)
  },

  notifyPromoted(uid) {
    return axios.post(`/admin/post-feeds/${uid}/promote`)
  }
}
