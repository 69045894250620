import axios from "@/libs/axios";
import { stringifyGetParams } from "@/helpers/api-params";

export default {
  getUsers(page = 1, pageSize = 10, search = null, filters) {
    const getParams = stringifyGetParams({
      page,
      pageSize,
      search,
      ...filters,
    });
    return axios.get("/users/administration?" + getParams);
  },
  getUsersWithoutCrew(page = 1, pageSize = 10, search = null, filters) {
    const getParams = stringifyGetParams({
      page,
      pageSize,
      search,
      ...filters,
    });
    return axios.get("/users/administration/nocrew?" + getParams);
  },

  sendMyCvAllowedToggle(uid, allowed) {
    return axios.patch(
      "/users/administration/" + uid + "/sendMyCvRecruitersPermission",
      { sendMyCvAllowed: allowed }
    );
  },

  getUsersFilters() {
    return axios.get(`/users/administration/filters`);
  },
  getReportedUsers(page = 1, pageSize = 10, search = null, filters) {
    const getParams = stringifyGetParams({
      page,
      pageSize,
      search,
      ...filters,
    });
    return axios.get("/users/reported-users?" + getParams);
  },
  getAffiliateUsers(page = 1, pageSize = 10, search = null) {
    const getParams = stringifyGetParams({ page, pageSize, search });
    return axios.get("/users/referal?" + getParams);
  },
  getCreatedUsers(page, search, pageSize, id) {
    return axios.get("/users/referals-for-user?uid=" + id, {
      params: {
        page: page,
        search: search,
        pageSize: pageSize,
      },
    });
  },
  changeUserStatus(uid, status) {
    return axios.put("/users/update-referal-status ", { uid, status });
  },
  toggleProfileVisibility(uid) {
    return axios.patch(`/users/crews/${uid}/profile-visibility`);
  },
  deleteProfile(uid) {
    return axios.delete(`/users/${uid}`);
  },
  exportUsersToExel(page = 1, pageSize = 10, search = null, filters) {
    const getParams = stringifyGetParams({
      page,
      pageSize,
      search,
      ...filters,
    });
    return axios.get("/users/administration/export?" + getParams, {
      responseType: "blob",
    });
  },
  changeUserBlockStatus(uid) {
    return axios.patch(`/users/${uid}/change-activation`);
  },
  toggleUserPremiumStatus(uid) {
    return axios.patch(`/users/crews/${uid}/premium`);
  },
  toggleCompanyVerified(uid) {
    return axios.patch(`/users/recruiters/${uid}/verify`);
  },
  updateCompanyPremium(uid, from, to) {
    return axios.patch(`/users/recruiters/${uid}/premium`, {
      from,
      to,
    });
  },
};
