import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: () => import("@/views/Dashboard"),
      name: "dashboard",
      meta: {
        auth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/Users"),
      meta: {
        pageTitle: "Users",
        auth: true,
        breadcrumb: [
          {
            text: "Users",
            active: true,
          },
        ],
      },
    },
    // {
    //   path: "/send-my-cv/recruiters",
    //   component: () => import("@/views/SendMyCV/Recruiters/index"),
    //   name: "recruiters",
    //   meta: {
    //     auth: true,
    //   },
    // },
    // {
    //   path: "/send-my-cv/recruiters/:id",
    //   name: "recruiters",
    //   component: () => import("@/views/SendMyCV/Recruiters/edit"),
    // },
    {
      path: "/reported-users",
      name: "reported-users",
      component: () => import("@/views/Users/ReportedUsers"),
      meta: {
        pageTitle: "Reported Users",
        auth: true,
        breadcrumb: [
          {
            text: "Reported Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/blocked-users",
      name: "blocked-users",
      component: () => import("@/views/Users/BlockedUsers"),
      meta: {
        pageTitle: "Blocked Users",
        auth: true,
        breadcrumb: [
          {
            text: "Blocked Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/affiliate-users",
      name: "affiliate-users",
      component: () => import("@/views/Users/AffiliateUsers"),
      meta: {
        pageTitle: "Affiliate Users",
        auth: true,
        breadcrumb: [
          {
            text: "Affiliate Users",
            active: true,
          },
        ],
      },
    },
    {
      path: "/posts",
      name: "posts",
      component: () => import("@/views/Posts"),
      meta: {
        pageTitle: "Posts",
        auth: true,
        breadcrumb: [
          {
            text: "Posts",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sponsored-posts",
      name: "sponsored-posts",
      component: () => import("@/views/Posts/sponsored-posts"),
      meta: {
        pageTitle: "Sponsored posts",
        auth: true,
        breadcrumb: [
          {
            text: "Sponsored posts",
            active: true,
          },
        ],
      },
    },
    {
      path: "/reported-posts",
      name: "reported-posts",
      component: () => import("@/views/Posts/ReportedPosts"),
      meta: {
        pageTitle: "Reported posts",
        auth: true,
        breadcrumb: [
          {
            text: "Reported posts",
            active: true,
          },
        ],
      },
    },
    {
      path: "/jobs",
      component: () => import("@/views/Jobs/index"),
      redirect: "/jobs/pending",
      children: [
        {
          path: "pending",
          name: "jobs-pending",
          component: () => import("@/views/Jobs/Pending"),
        },
        {
          path: "active",
          name: "jobs-active",
          component: () => import("@/views/Jobs/Active"),
        },
        {
          path: "draft",
          name: "jobs-draft",
          component: () => import("@/views/Jobs/Draft"),
        },
      ],
    },
    {
      path: "/blogs",
      name: "blogs",
      component: () => import("@/views/Blogs/index"),
    },
    {
      path: "/blogs/:id",
      name: "blogs",
      component: () => import("@/views/Blogs/edit"),
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/News/index"),
    },
    {
      path: "/news/:id",
      name: "news",
      component: () => import("@/views/News/edit"),
    },
    {
      path: "/send-my-cv",
      name: "send-my-cv",
      component: () => import("@/views/SendMyCV/index"),
      meta: {
        pageTitle: "Send My CV",
        auth: true,
        breadcrumb: [
          {
            text: "Send My CV",
            active: true,
          },
        ],
      },
    },
    {
      path: "/recruiters",
      name: "recruiters",
      component: () => import("@/views/SendMyCV/Recruiters/index"),
      meta: {
        pageTitle: "Recruiters",
        auth: true,
        breadcrumb: [
          {
            text: "Recruiters",
            active: true,
          },
        ],
      },
    },
    {
      path: "/external-recruiters",
      name: "external-recruiters",
      component: () => import("@/views/SendMyCV/ExternalRecruiters/index"),
      meta: {
        pageTitle: "External Recruiters",
        auth: true,
        breadcrumb: [
          {
            text: "External Recruiters",
            active: true,
          },
        ],
      },
    },
    {
      path: "/external-recruiters/:id",
      name: "external-recruiters-edit",
      component: () => import("@/views/SendMyCV/ExternalRecruiters/edit"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        guest: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        guest: true,
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
      return;
    }
    next();
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
